import React, { Component } from 'react';
import Header from "../headers/Header";
import Footer from "../footers/Footer";
import axios from "../../axios/axios"
import reacthtmlparser from "react-html-parser";
import {seo} from '../../../helpers/seo';
import { Container } from 'react-bootstrap';

export default class Privacy extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:true,
            description: "",
        }
    }
    
    fetchPrivacyPolicy = async () => {
        this.setState({ loading: true });
        await axios
        .get(`${process.env.REACT_APP_API_URL}/get_privacypolicy_content`, {
            withCredentials: true,
            
        })
        .then((res) => {
            console.log("privacy response...", res);
            if(res.data.success === true) {
                this.setState({ description: res.data.deta[0].description});
            }
        })
        .catch((error) => {
            this.setState({ loading: false });
            console.log("error", error);
        });
    };
    
    componentDidMount() {
        this.fetchPrivacyPolicy();
        this.setState({loading:false});
        seo({
            title: 'Privacy Policy'
        });
    };
  render() {
    return (
      <React.Fragment>
        {this.state.loading?'Loading...':''}
        <Header />

        <div className='main-content'>
            <Container>
            <div className="headingMain">
                <h2>Privacy Policy</h2>
            </div>            
            {reacthtmlparser(this.state.description)}
            </Container>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}
