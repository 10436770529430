import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/style.scss";
import "./app.css";
// import BeatLoader from "react-spinners/BeatLoader";
import { Spinner } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import {
  // HashRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
// import axios from "../src/views/appUser/axios/axios"; 

import SimpleReactLightbox from "simple-react-lightbox";
import RecommendationPdf from "./views/appUser/layouts/pdf file/RecommendationPdf";
import BucketPdf from "./views/appUser/layouts/pdf file/BucketPdf";
import Privacy from "./views/appUser/components/privacy/Privacy";
import TermsAndConditions from "./views/appUser/components/termsConditions/TermsAndConditions";
import NoQuestionThankU from "./views/appUser/layouts/NoQuestionThankU";
// import RecommendationImplemented from "./views/appUser/layouts/RecommendationImplemented";
import ImplementedRecommendation from "./views/appUser/layouts/ImplementedRecommendation";
import RecommendationDeclined from "./views/appUser/layouts/RecommendationDeclined";
import RecommendationDeclinedPdf from "./views/appUser/layouts/pdf file/RecommenationDeclinedPdf";

// const loading = (
//   <div className="pt-3 text-center">
//     <div className="sk-spinner sk-spinner-pulse"></div>
//   </div>
// );

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// frontend Pages
const Home = React.lazy(() => import("./views/appUser/layouts/Home"));
const AppContact = React.lazy(() =>
  import("./views/appUser/components/contact/contact")
);
const Aboutus = React.lazy(() =>
  import("./views/appUser/components/aboutus/Aboutus")
);

const Stocket = React.lazy(() =>
  import("./views/appUser/components/stocket/Stocket")
);

const Faq = React.lazy(() =>
  import("./views/appUser/components/FAQ/Faq")
);

const ThankYOU = React.lazy(() => import("./views/appUser/layouts/Thankyou"));
const Registration = React.lazy(() =>
  import("./views/appUser/auth/Registration")
);
const Login = React.lazy(() => import("./views/appUser/auth/Login"));
const UserProfile = React.lazy(() =>
  import("./views/appUser/layouts/UserProfile")
);
const UserProgress = React.lazy(() =>
  import("./views/appUser/layouts/UserProgress")
);
const Achievements = React.lazy(() =>
  import("./views/appUser/layouts/Achievements")
);

const Bucket = React.lazy(() =>
  import("./views/appUser/layouts/Bucket")
);

const ForgotPassword = React.lazy(() =>
  import("./views/appUser/auth/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/appUser/auth/ResetPassword")
);
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const SurveyList = React.lazy(() =>
  import("./views/appUser/layouts/SurveyList")
);
const Surveydetails = React.lazy(() =>
  import("./views/appUser/layouts/Surveydetails")
);
// const Surveyquestionanswer = React.lazy(() =>
//   import("./views/appUser/layouts/Questionanswersurvey")
// );
const AnswerQuestion = React.lazy(() =>
  import("./views/appUser/layouts/Answerquestion")
);
const UserAllServey = React.lazy(() =>
  import("./views/appUser/layouts/UserAllServey")
);
const UserRecommentdation = React.lazy(() =>
  import("./views/appUser/layouts/UserRecommentdation")
);
const RecommendationsList = React.lazy(()=>
  import("./views/appUser/layouts/RecommendationsList")
);
const RecommendationsPdf = React.lazy(() => 
  import("./views/appUser/layouts/Recommendationspdf")
);

// const RecommendationPdf = React.lazy(() => 
//   import ("./views/appUser/layouts/pdf file/RecommendationPdf")
// );
// const BucketPdf = React.lazy(() => 
//   import ("./views/appUser/layouts/pdf file/BucketPdf")
// );

const UserFarm = React.lazy(() => import("./views/appUser/layouts/Farm/Farm"));
const Welcome = React.lazy(() => import("./views/appUser/layouts/Welcome"));
const Pdf = React.lazy(() => import("./views/admin/survey/Pdf"));
// backend
const Adminlogin = React.lazy(() => import("./views/admin/login/Login"));

//admin define
const ADMIN = "/admin";

class App extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     user_id: JSON.parse(localStorage.getItem('userdata')),
  //     web_details: [],
  //     userId: '',
  //     analyzer_id: '',
  //     user_ip_address: '',
  //     db_ip_address: '',
  //     home_count: '',
  //     contactus_count: '',
  //     aboutus_count: '',
  //     bsa_stocket_count: '',
  //     stocket_bsa_count: '',
  //     analyzer_count: '',
  //     recommendation_count: '',
  //   };
  // }
  componentDidMount() {
    // this.get_users_details();
    //localStorage.setItem("AuthData", JSON.stringify("abcd"));
    var adminUrl = localStorage.getItem("ADMINURL");
    if (adminUrl === null) {
      localStorage.setItem("ADMINURL", ADMIN);
    } else {
      if (adminUrl !== ADMIN) {
        localStorage.setItem("ADMINURL", ADMIN);
      }
    }
    
    // this.get_web_info();
    
    // setTimeout(() => {
    //   this.insert_web_info();
    // }, 2000);
  }

  // get_users_details = async() =>{
  //   await axios
  //     .get(`https://ipapi.co/json/`)
  //     .then((res)=>{
  //       this.setState({user_ip_address: res.data.ip});
  //         console.log("users full details...", res.data);
  //     })
  //     .catch((err)=>{
  //         console.log("error", err);
  //     })
  // }
  
  // get_web_info = async() => {
  //   await axios.get(`/user_web_information`)
  //   .then((res) => {
  //     let {details} = res.data;
  //     this.setState({web_details: details})
      
  //     details.map((data, ind) => {
  //       this.setState({home_count: data.home_count + 1})
  //     })
      
  //     console.log("details......", details);
  //   })
  //   .catch((error) => {
  //     console.log("error-message: ", error);
  //   })
  // }
  
  // insert_web_info = async() => {
  //   this.setState({aboutus_count: !this.state.web_details.some(data => data.ip_address === this.state.user_ip_address)});
    
  //   if(!this.state.web_details.some(data => data.ip_address === this.state.user_ip_address)){
  //     let formData = {
  //       home_count: this.state.home_count,
  //       user_ip_address: this.state.user_ip_address,
        
  //     };
      
  //     try {
  //       const apiUrl = '/add_user_web_information';
  //       const response = await axios.post(apiUrl, formData);
  //       console.log('Response from server:', response.data);
  //     } catch (error) {
  //       console.error('Error sending data to server:', error);
  //     }
      
  //   }
  // }
  

  render() {
    return (
      <SimpleReactLightbox>
        <BrowserRouter>
          <React.Suspense
            fallback={
              // <BeatLoader
              //   css="margin-left:50%;margin-top:100px;"
              //   color="#000000"
              //   size={30}
              // />
              <LoadingOverlay
                active={true}
                spinner={<Spinner animation="grow" variant="success" size="lg" />}
              />
            }
          >
            <Switch>
              {/* frontend routes */}
              <Route
                exact
                path="/"
                name="Home"
                render={(props) => <Home {...props} />}
              />
              <Route
                exact
                path="/contact"
                name="contact"
                render={(props) => <AppContact {...props} />}
              />
              <Route
                exact
                path="/about-us"
                name="aboutus"
                render={(props) => <Aboutus {...props} />}
              />
              <Route
                exact
                path="/stocket"
                name="stocket"
                render={(props) => <Stocket {...props} />}
              />
              <Route
                exact
                path="/privacy"
                name="privacy"
                render={(props) => <Privacy {...props} />}
              />
              <Route
                exact
                path="/terms-conditions"
                name="Terms and Conditions"
                render={(props) => <TermsAndConditions {...props} />}
              />
              <Route
                exact
                path="/faq"
                name="faq"
                render={(props) => <Faq {...props} />}
              />
              <Route
                exact
                path="/no-question-thanks"
                name="no question thank you"
                render={(props) => <NoQuestionThankU {...props} />}
              />

              <Route
                exact
                path="/registration"
                name="Register Page"
                render={(props) => <Registration {...props} />}
              />
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/forgot-password"
                name="ForgotPassword Page"
                render={(props) => <ForgotPassword {...props} />}
              />
              <Route
                exact
                path="/reset-password/:token"
                name="Reset Password Page"
                render={(props) => <ResetPassword {...props} />}
              />
              <Route
                exact
                path="/account-activation/:token"
                name="Redirect Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/thankyou"
                name="Thanks page"
                render={(props) => <ThankYOU {...props} />}
              />

              <Route
                exact
                path="/survey-list"
                name="Survey page"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <SurveyList {...props} />
                  )
                }
              />
              <Route
                exact
                path="/survey-details/:slug"
                name="Survey details"
                render={(props) =>
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <Surveydetails {...props} />
                  )
                }
              />
              <Route
                exact
                path="/survey/:id"
                name="Survey details"
                render={(props) =>
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <AnswerQuestion {...props} />
                  )
                }
              />
              {/* <Route exact path="/survey/:id" name="Survey details" render={props => 
                localStorage.getItem("authToken") === null ? (
                  <Redirect to="/login" />
                ) : (
                  <Surveyquestionanswer {...props}/>
                  ) 
              } 
              /> */}

              {/* <Route exact path="/user-profile" name="User profile page" render={props => <UserProfile {...props}/>} /> */}

              {/* ____________________after_login_views_________________ */}

              <Route
                path="/user-profile"
                name="User profile page"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <UserProfile {...props} />
                  )
                }
              />
              
              {/* <Route
                path="/user-progress"
                name="User progress page"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <UserProgress {...props} />
                  )
                }
              /> */}
              
              {/* <Route
                path="/achievements"
                name="Achievements page"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <Achievements {...props} />
                  )
                }
              /> */}

              <Route
                path="/bucket"
                name="Bucket page"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/bucket" />
                  ) : (
                    <Bucket {...props} />
                  )
                }
              />
              <Route
                path="/bucket-pdf"
                name="Bucket pdf page"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <BucketPdf {...props} />
                  )
                }
              />
              <Route
                path="/implemented-recommendation-pdf"
                name="Bucket pdf page"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <BucketPdf {...props} />
                  )
                }
              />
              
              <Route
                path="/user-servey"
                name="User servey page"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <UserAllServey {...props} />
                  )
                }
              />
              <Route
                exact
                path="/my-recommendations-list"
                name="Recommendations List"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <RecommendationsList {...props} />
                  )
                }
              />
              <Route
                exact
                path="/my-recommendations-list/pdf"
                name="Recommendation pdf"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <RecommendationsPdf {...props} />
                  )
                }
              />
              {/* <Route
                path="/recommendation-implemented"
                name="Recommendation implemented page"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <RecommendationImplemented {...props} />
                  )
                }
              /> */}
              <Route
                path="/implemented-recommendations"
                name="Implemented recommendations page"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <ImplementedRecommendation {...props} />
                  )
                }
              />
              <Route
                path="/recommendation-declined"
                name="Recommendation declined page"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <RecommendationDeclined {...props} />
                  )
                }
              />
              <Route
                path="/user-recommendation"
                name="User servey page"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <UserRecommentdation {...props} />
                  )
                }
              />
              <Route
                path="/user-recommendation-pdf"
                name="User recommendation pdf"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <RecommendationPdf {...props} />
                  )
                }
              />
              <Route
                path="/recommendation-declined-pdf"
                name="recommendation declined pdf"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <RecommendationDeclinedPdf {...props} />
                  )
                }
              />
              <Route
                path="/user-farm"
                name="User farm page"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <UserFarm {...props} />
                  )
                }
              />

              <Route
                path="/welcome"
                name="Welcome"
                render={(props) =>
                  // <Coach />
                  localStorage.getItem("authToken") === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <Welcome {...props} />
                  )
                }
              />

              <Route
                path="/pdf"
                name="pdf"
                render={(props) => <Pdf {...props} />}
              />
              {/* <Route name="Page 404" render={props => <Page404 {...props}/>} /> */}
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />

              {/* Admin routes */}
              <Route
                exact
                path={ADMIN + "/login"}
                name="Login Page"
                render={(props) => <Adminlogin {...props} />}
              />
              <Route
                path={ADMIN}
                name="Admin"
                render={(props) => <TheLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </SimpleReactLightbox>
    );
  }
}

export default App;
