import React, { Component } from "react";
import Header from "../../components/headers/Header";
import Footer from "../../components/footers/Footer";
import Rightpanel from "../../layouts/Rightpanel";
import LoadingOverlay from "react-loading-overlay";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "../../axios/axios";
import html2pdf from 'html2pdf.js';
import reacthtmlparser from "react-html-parser";

const ref = React.createRef();

export default class RecommendationPdf extends Component {
    constructor(props){
        super(props);
        this.token = localStorage.getItem("authToken");
        this.state = {
        allRecommendation: "",
        analyzers:[],
        fadeOut: {},
        };
    }
    
    componentDidMount() {
        this.getRecommendation();
      }
      
      getRecommendation = () => {
        axios({
          method: "post",
          url: process.env.REACT_APP_API_URL + "/user-all-recommentation",
    
          headers: {
            "x-auth": this.token,
          },
        })
          .then(async (res) => {
            if (res.data) {
              this.setState(
                { 
                  allRecommendation: res.data.data,
                  analyzers: res.data.analyzer
               });
            }
          })
          .catch((err) => {
            // console.log("error", err);
          });
      };
      
      generatePDF = () =>
      {
        var doc= document.querySelector("#content");
    
        var options = {
            filename: 'recommendation.pdf',
            margin: [0.8,0.4,0.8,0.4],
            pagebreak: {mode: 'avoid-all', avoid:"h5"},
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
        }
        html2pdf().set(options).from(doc).save();
      }
      
      goToUserRecommendation = () => {
        this.props.history.push({
        pathname: "/user-recommendation"
        // pathname: "/recommendation-implemented"
    });
      };
      
  render() {
    const {allRecommendation, analyzers} = this.state;
    return (
        <React.Fragment>
            <Header />
            <div className="main-content">

                <LoadingOverlay
                    // active={loader}
                    spinner={<BeatLoader color="#fff" size={30} />}
                    //text='Loading your profile...'
                >
                    <div className="container">
                        <div className="row">
                            <Rightpanel activePath="bucket" />
                            <div className="col-md-8">
                                <div className="card" style={{width:"595px"}}>
                                    <div className="card-header d-flex justify-content-between">
                                    <button onClick={this.generatePDF} className="btn btn-primary" type="primary" style={{backgroundColor: "#13359a", borderColor: "#13359a"}}>Download</button>
                                    <button onClick={this.goToUserRecommendation} className="btn btn-primary" type="primary" style={{backgroundColor: "#13359a", borderColor: "#13359a"}}>Back</button>
                                    </div>
                                
                                    <div className="card-body" id="content" ref={ref} style={{width:"595px", fontSize:"10px", padding:"10px"}}> 
                                        <div className="mb-2">
                                        <h4>Recommendations</h4>
                                        </div>
                                    {
                                        analyzers && analyzers?.map((item,i)=>{
                                            return(
                                                <div key={i}>
                                                    <div className="mb-2" key={i}>
                                                        <h5><b>{i+1}. Analyzer: </b>{item.title}</h5>
                                                    </div>  
                                                    <div className="mb-2" style={{marginLeft: "15px"}}>
                                                        <b style={{fontSize: "12px"}}> Auther: </b>
                                                    </div>
                                                    {
                                                        allRecommendation.length > 0 
                                                        ? allRecommendation && allRecommendation.map((data,i)=>{
                                                            return(
                                                                <div key={i}>
                                                                {
                                                                    data.bfanalyzer_survey_id === item.bfanalyzer_survey_id ?
                                                                        <div className="d-flex justify-content-between align-items-center" key={i}>
                                                                        
                                                                            {(data.rec_options !== "Yes" && data.reason_uploaded !== "true") ?
                                                                                <>
                                                                                    <div className="listing_left">
                                                                                            <div className="mb-2">
                                                                                                <b style={{fontSize: "15px"}} >Recommendation: </b><span style={{fontSize: "0.8rem"}}>{i+1}. {data.name}</span>
                                                                                            </div>
                                                                                            <div className="mb-2">
                                                                                                <b>Content:</b> {reacthtmlparser(data.content)}
                                                                                            </div>
                                                                                                {/* <p>
                                                                                                    <b>Content: </b>
                                                                                                    {
                                                                                                        data.content?.length>0 ? reacthtmlparser(data.content): "No content provided"
                                                                                                    }
                                                                                                </p> */}
                                                                                            <br></br>
                                                                                    </div>
                                                                                </>
                                                                            :<></>}
                                                                        </div>
                                                                    : <></>
                                                                }
                                                                    {/* <div className="mb-2">
                                                                        <b style={{fontSize: "15px"}} >Recommendation: </b><span style={{fontSize: "0.8rem"}}>{i+1}. {data.name}</span>
                                                                    </div> */}
                                                                </div>
                                                            )
                                                        })
                                                        : "No Data"
                                                    }
                                                
                                                    <br/>
                                                    <div >
                                                    
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
            
            <Footer />
        </React.Fragment>
    )
  }
}