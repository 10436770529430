import React, { Component } from "react";
import { Modal } from "react-bootstrap";
// import {save} from "file-saver";
import { Spinner } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import axios from "axios";
import "file-upload-with-preview/dist/file-upload-with-preview.min.css";

import "react-toastify/dist/ReactToastify.css";

import reacthtmlparser from "react-html-parser";
// import { CForm, CFormGroup, CLabel } from "@coreui/react";
class RecommendationModal extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("authToken");
    this.uploadphoto = "";
    this.uploadvideo = "";
    this.state = {
      photos: [],
      loading: false,
      recommendation: [],
      pdf: [],
      downloadButton: true,
      fileUrl: ''
    };
  }

  getRecommendation = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/get-recommendation-by-id/${this.props.recommendationid}`,
        {
          // withCredentials: true,
          headers: {
            "x-auth": this.token,
          },
        }
      )
      .then((res) => {
        //console.log("ecom",res);
        //toast.success("Photo successfully uploaded");
        this.setState({ recommendation: res.data.data });
        return axios.get(
          `${process.env.REACT_APP_API_URL}/get-media/${this.props.recommendationid}`,
          {
            // withCredentials: true,
            headers: {
              "x-auth": this.token,
            },
          }
        );
      })
      .then((res) => {
        this.setState({ loading: false, photos: res.data.data });
        // console.log("media",this.state.photos)
      })
      .catch((err) => {
        toast.warning("Server Error");
        this.setState({ loading: false });
      });
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.show &&
      this.props.recommendationid !== prevProps.recommendationid
    ) {
      this.setState({ loading: true });
      this.getRecommendation();
    }
  }
  hide = () => {
    this.setState({ photos: [], recommendation: [] });
    this.props.handleShow();
  };

  getMyMedia =(data)=>{
    this.setState({
      downloadButton: true,
      pdf: data,
    });
    this.state.pdf.map((item,i)=>{
      
      //const url = `data:application/pdf;base64,${item.body}`;
      const url = `data:${item.type};base64,${item.body}`;
      const a = document.createElement('a');
      a.href=url;
      const parts = item.type.split("/");
      const type = parts[1];
      // console.log("type",type)
      a.setAttribute('download',`file${i}.${type}`);
      document.body.appendChild(a);
      a.click();
      
      return true;
    })
  }
  download = async ()=>{
    const id =[];
    const {photos} = this.state;
    photos.map((item,i)=>{
      return id[i]=item.id;
    })
    this.setState({ downloadButton: false})
    // toast.success('Please wait while we prepare your files', {
    //   autoClose: 3000,
    // })
    await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/download-media`,
      withCredentials:true,
      data: id,
      // onUploadProgress: (progressEvent)=> {
      //   const {loaded, total} = progressEvent;
      //   let percent = Math.floor((loaded * 100)/total)
      //   console.log(`${loaded}kb of ${total}kb | ${percent}%`)
      // },
    })
    .then((res)=>{
      setTimeout(()=>{
        // toast.success("Downloading", {autoClose: 4000})
        // console.log("success")
        // console.log(res.data.data);
        this.getMyMedia(res.data.data);
      }, 2000)
    })
    .catch((err)=>{
      // console.log('error', err);
      this.setState({ downloadButton: true})
      toast.error("Error")
    })
  }
  render() {
    let currrecom = this.state.recommendation[0];
    // console.log(this.state.recommendation[0]);
    //console.log("err",this.state.err)
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={this.hide}
          animation={false}
          size="lg"
        >
          <LoadingOverlay
            active={this.state.loading}
            spinner={<Spinner animation="grow" variant="primary" size="lg" />}
          >
            <Modal.Header closeButton>
              <Modal.Title>Recommendation Details </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                  <h5>Name:</h5>
                  {currrecom ? currrecom.name : ""}
                </div>

                <div>
                  <h5>Content:</h5>
                  {reacthtmlparser(currrecom ? currrecom.content : "")}
                </div>

                <div onChange={this.props.cost}>
                  <h5>Estimated Cost:</h5>
                  
                    {currrecom
                      ? currrecom.estimated_cost
                        ? "$ " + currrecom.estimated_cost
                        : "---"
                      : ""}
                  
                </div>

                <div>
                  <h5>Recommendation Time:</h5>
                 
                    {currrecom
                      ? currrecom.user_recomm_limit_span_or_number
                        ? currrecom.user_recomm_limit_span_or_number +
                          "-" +
                          currrecom.user_recomm_limit_span_type
                        : "---"
                      : ""}
                  
                </div>

                <div>
                  <h5>Files:</h5>
                  <div className="row mx-0 deletediv">
                    {this.state.photos.map((item, id) => {
                      /* {console.log(item,"item")} */
                      return (
                        <div className="col-md-4 deletechild" key={id}>
                          {item.type.match("image.*") ? (
                            <img
                              className="imgsize"
                              src={item.url}
                              alt="img"
                              onClick={() =>
                                window.open(`${item.url}`, "_blank")
                              }
                            />
                          ) : (
                            <iframe
                              className="imgsize"
                              src={item.url}
                              title="iframe"
                              onClick={() =>
                                window.open(`${item.url}`, "_blank")
                              }
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <button className="btn btn-primary mt-2" onClick={this.download} disabled={!this.state.downloadButton}>Download</button>
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
            <Button variant="primary" onClick={this.saveallAnswer}>
              Save Changes
            </Button>
            <Button variant="secondary" onClick={this.props.handleShow}>
              Close
            </Button>
          </Modal.Footer> */}
          </LoadingOverlay>
        </Modal>
      </>
    );
  }
}

export default RecommendationModal;
