import React, { useEffect, useState } from 'react';
import axios from "../../axios/axios";
import Header from "../../components/headers/Header";
// import Footer from "../../components/footers/Footer";
import Rightpanel from "../../layouts/Rightpanel";
import LoadingOverlay from "react-loading-overlay";
import BeatLoader from "react-spinners/BeatLoader";
import reacthtmlparser from "react-html-parser";
import html2pdf from 'html2pdf.js';
import { useHistory } from 'react-router-dom';
const ref = React.createRef();

const BucketPdf = () => {
    const history = useHistory();
    const [bucketAnalyzer, setBucketAnalyzer] = useState([]);
    const [bucketAchievements, setBucketAchievements] = useState([]);
    const [count, setCount] = useState(0)
    
    let token = localStorage.getItem("authToken");
    
    const getBucketData = async() => {
        await axios
        .get(`/bucket`,
            {
                headers: {"x-auth": token}
            })
            .then((res) => {
                // console.log("bucket-data...", res.data);
                
                if(res.data.status === "success"){
                    setCount(res.data.pagination.total_items?.[0])
                }
            })
            .catch((error) => {
                // console.log("error", error);
            })
    }
    
    
    const getBucket = async() => {
        await axios
        .get(`/bucket?limit=${count.count}`,
            {
                headers: {"x-auth": token}
            })
            .then((res) => {
                // console.log("bucket-data for 2nd log...", res.data);
                
                if(res.data.status === "success"){
                    setBucketAnalyzer(res.data.analyzers);
                    setBucketAchievements(res.data.achievements);
                }
            })
            .catch((error) => {
                // console.log("error", error);
            })
    }
    // console.log("...........",count)
    
    useEffect(() => {
        getBucketData();
        
        count !== 0 ? getBucket() : getBucketData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count !== 0]);
    
    const generatePDF = () => { 
        var doc= document.querySelector("#content");
    
        var options = {
            filename: 'bucket.pdf',
            margin: [0.8,0.4,0.8,0.4],
            pagebreak: {mode: 'avoid-all', avoid:"h5"},
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
        }
        html2pdf().set(options).from(doc).save();
    }
    
    const goToImplementedRec = () => {
        // history.push("/bucket")
        history.push("/implemented-recommendations")

    }
    
  return (
    <React.Fragment>
        <Header />
        <div className="main-content">
            <LoadingOverlay
                // active={loader}
                spinner={<BeatLoader color="#fff" size={30} />}
                //text='Loading your profile...'
            >
            <div className="container">
                <div className="row">
                    <Rightpanel activePath="bucket" />
                    <div className="col-md-8">
                        <div className="card" style={{width:"595px"}}>
                            <div className="card-header d-flex justify-content-between">
                            <button onClick={generatePDF} className="btn btn-primary" type="primary" style={{backgroundColor: "#13359a", borderColor: "#13359a"}}>Download</button>
                            <button onClick={() => goToImplementedRec()} className="btn btn-primary" type="primary" style={{backgroundColor: "#13359a", borderColor: "#13359a"}}>Back</button>
                            </div>
                            
                            <div className="card-body" id="content" ref={ref} style={{width:"595px", fontSize:"10px", padding:"10px"}}> 
                                <div className="mb-2">
                                <h4>Implemented Recommendation</h4>
                                </div>
                            { 
                                bucketAnalyzer && bucketAnalyzer.map((item,i)=>{
                                let count = 0;
                                    return(
                                        <div key={i}>
                                            <div className="mb-2">
                                                <h5><b>{i+1}. Analyzer: </b>{item.title}</h5>
                                            </div>
                                            
                                            {
                                                bucketAchievements && bucketAchievements.map((data,i)=>{
                                                    data.survey_id === item.survey_id ? count += 1 : count += 0;
                                                    return(
                                                        <div key={i}>
                                                            {
                                                                data.survey_id === item.survey_id ?
                                                                <>
                                                                    <div className="mb-2">
                                                                        <b>Recommendation: </b><span style={{fontSize: "0.8rem"}}>{count}. {data.name}</span>
                                                                    </div>
                                                                    <div className="mb-2">
                                                                        <b>Content:</b> {reacthtmlparser(data.content)}
                                                                    </div>
                                                                </> :
                                                                <></>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                            <br/>
                                            <div >
                                                
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </LoadingOverlay>
        </div>
    </React.Fragment>
  )
}

export default BucketPdf;
