import React, { Component } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { ToastContainer } from 'react-toastify';
import Header from '../components/headers/Header';
import Footer from "../components/footers/Footer";
import axios from "../axios/axios";

export default class NoQuestionThankU extends Component {
    constructor(props){
        super(props);
        this.token = localStorage.getItem("authToken");
        this.state = {
            loading: false,
            survey_id: this.props.location.data,
            questionAnswer: [],
        }
    }
    getQuestionAnswer = async() => {
      console.log("survey_id survey_id...", this.state.survey_id);
      this.setState({ loading: true });
      await axios({
        method: "get",
        url: process.env.REACT_APP_API_URL + `/not-found-question-answer?survey_id=${this.state.survey_id}`,
        // data: ....,
        headers: {
          "x-auth": this.token,
        },
      })
      .then((response) => {
        console.log("response......", response);
        this.setState({ loading: false });
        this.setState({questionAnswer: response.data.result});
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ loading: false });
      })
    }
    
    componentDidMount = () => {
      this.getQuestionAnswer();
    }
    
    continueAnalyzer = () => {
      this.props.history.push({
        pathname: `/survey-list`
      });
    }
  render() {
    console.log("///////", this.state.questionAnswer);
    return (
        <React.Fragment>
        <LoadingOverlay
          active={this.state.loading}
          spinner={<Spinner animation="grow" variant="primary" size="lg" />}
        >
          <Header />
          
          <section className="main-content">
            <div className="container">
              <Card  className="bsa_card">
              <Card.Header>
                <Card.Title className="mb-0">Thank you for visiting us.</Card.Title>
              </Card.Header>
                
                <Card.Body>
                  <h4> Currently we do not have in recommendations for you. </h4>
                  <p> You have selected the following questions and answers- </p>
                  
                  {
                    this.state.questionAnswer.map((data, ind) => {
                      return(
                        <>
                          <div style={{marginBottom: "1.5rem"}}>
                            <strong>{ind+1}. {" "}Question:-</strong><span dangerouslySetInnerHTML={{__html: data.question}} /><br />
                            <strong>Answer:- </strong><span dangerouslySetInnerHTML={{__html: data.answer}} />
                          </div>
                        </>
                      )
                    })
                  }
                  
                  <Card.Footer>
                    <div className="text-left">
                      <button
                        type="button"
                        onClick={this.continueAnalyzer}
                        className="btn btn-success"
                      >
                        CONTINUE
                      </button>
                    </div>
                  </Card.Footer>
                  
                </Card.Body>
              </Card>
            </div>
          </section>
          <Footer />
          <ToastContainer />
        </LoadingOverlay>
      </React.Fragment>
    )
  }
}
