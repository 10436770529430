/* eslint-disable no-script-url */
import React, { Component } from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { NavLink, withRouter } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import {
  finallogo,
  avatar,
} from "../../../../assets/images/";
// import { FLogin, GLogin } from "../../auth/social";
import "antd/dist/antd.css";
import axios from "../../axios/axios";
import "./header.css";

import { ukyLogo } from "../../../../assets/images";
import { Encrypt } from "src/securityFile/Security";
import { message } from "antd";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: props.location.pathname,
      userimage: "",
      username: "",
      searchvalue: "",
      intervalId:"",
      encriptUserData: "",
    };
    // console.log("pathname", props.location.pathname);
    //alert(props.location.pathname)
  }
  componentDidMount() {
    if (localStorage.getItem("userdata")) {
      const userdata = JSON.parse(localStorage.getItem("userdata"));
      const userimage = userdata.image;
      let umi = "";
      if (userimage !== null) {
        umi = `https://api.x10dadmin.com/storage/${userimage}`;
      } else {
        umi = avatar;
      }
      //const um = localStorage.setItem("userimage", `https://api.x10dadmin.com/storage/${userimage}`);
      const username = userdata.name;
      this.setState({ userimage: umi, username: username, encriptUserData: localStorage.getItem("userdata") });
      //console.log('user image',userdata.name);
    }
    var last;
    if(localStorage.getItem("authToken")){
      const token = localStorage.getItem("authToken");
      //  last = setInterval(()=>{
        // console.log("bsa .....");
        axios.post("/user-last-activity",
        { token },
        {
          headers: { "x-auth": token },
        }
      )
      .then((res) => {
        // console.log("Last activity update", res);
      })
      .catch((err) => {});
      // },10000)
      this.setState({intervalId: last})
    }
  }
  // componentWillUnmount(){
  //   if(!localStorage.getItem("authToken")){
  //   clearInterval(this.state.intervalId)
  //   console.log('componentWillUnmount called')
  //   }
  // }
  logoutUser = () => {
    let self = this;
    // console.log("I am here");
    const hideMessage = message.loading("Please wait while logging you out", 0);

    const token = localStorage.getItem("authToken");
    localStorage.clear();
    if (token !== undefined) {
      axios
        .post(
          "/appuser-logout",
          { token },
          {
            headers: { "x-auth": token },
          }
        )
        .then((res) => {
          // console.log("res ", res);
          hideMessage();
          if (res.data.success) {
            // console.log("success", self.props.history);
            message.success("Logged out successfully", 3);
            self.props.history.push({
              pathname: "/login",
              // state: {
              //   userData: res.data.userdata,
              // },
            });
          }
        })
        .catch(() => {
          hideMessage();
        });
    }
  };

  onchangeSet = (event) => {
    // const searchvalue = event.target.value;
    this.setState({ searchvalue: event.target.value });
    // if(this.state.pathname == '/survey-list'){
    //   this.props.filterListHeader(searchvalue)
    // }else{
    //   this.props.history.push({
    //     pathname:"/survey-list",
    //   });
    // }

    //alert(searchvalue);
  };
  clickSubmit = (e) => {
    //alert(this.state.searchvalue)
    //window.href = `/survey-list`
    //window.location.href=`/survey-list?searchvalue=${this.state.searchvalue}`;
    // console.log(e);
    let code = e.charCode;
    if (code) {
      if (code === 13) {
        this.props.history.push({
          pathname: `/survey-list`,
          state: {
            searchvalue: this.state.searchvalue,
          },
        });
      }
    } else {
      this.props.history.push({
        pathname: `/survey-list`,
        state: {
          searchvalue: this.state.searchvalue,
        },
      });
    }
  };

  render() {
    return (
      <header className="header">
            <div className="navbar__middle">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-7 col-sm-6 col-6">
                    <Navbar.Brand>
                      <NavLink to="/">
                        <img src={finallogo} className="img-fluid" alt="brand" />
                      </NavLink>
                      <img src={ukyLogo} className="img-fluid" alt="brand" />
                    </Navbar.Brand>
                  </div>
                  <div className="col-md-5 col-sm-6 col-6">
                    <div className="site_search">
                      <span>
                        <i className="fa fa-search"></i> <span>Search</span>
                      </span>
                      <div className="search_input">
                        <div
                          onKeyPress={this.clickSubmit}
                          className="input-group"
                        >
                          <input
                            type="text"
                            onChange={this.onchangeSet}
                            value={this.state.searchvalue}
                            className="form-control"
                            placeholder="Search Analyzers..."
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-success"
                              onClick={this.clickSubmit}
                              type="button"
                            >
                              <i className="fa fa-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="navbar__bottom">
              <Container>
              <Navbar expand="md" className="">
                <Navbar.Toggle className="navbar_toggle" aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav as="ul">
                    <Nav.Item as="li">
                      <NavLink to="/" exact>HOME</NavLink>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <NavLink to="/about-us" exact>ABOUT US</NavLink>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <NavLink to="/survey-list" exact>Analyzers</NavLink>
                    </Nav.Item>
                    {/* <Nav.Item as="li"> */}
                      <NavDropdown as="li" title="Recommendations" id="basic-nav-dropdown">
                        <NavDropdown.Item>
                          <Nav.Item as="li">
                            <NavLink to="/user-recommendation" exact style={{color: "black"}}>Recommendations To Be Implemented</NavLink>
                          </Nav.Item>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                          <Nav.Item as="li">
                            <NavLink to="/implemented-recommendations" exact style={{color: "black"}}>Implemented Recommendations</NavLink>
                          </Nav.Item>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                          <Nav.Item as="li">
                            <NavLink to="/recommendation-declined" exact style={{color: "black"}}>Recommendations Declined To Implement</NavLink>
                          </Nav.Item>
                        </NavDropdown.Item>
                      </NavDropdown>
                      {/* <NavLink to="/user-recommendation" exact>Recommendations</NavLink> */}
                    {/* </Nav.Item> */}
                    {/* <Nav.Item as="li">
                      <NavLink to="/user-progress" exact>Progress</NavLink>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <NavLink to="/achievements" exact>Achievements</NavLink>
                    </Nav.Item> */}
                    <Nav.Item as="li">
                      <NavLink to="/contact" exact>CONTACT US</NavLink>
                    </Nav.Item>
                    {/* {localStorage.getItem("authToken") === null ? (
                      <>
                        <Nav.Item as="li">
                          <NavLink to="/login" exact>Login</NavLink>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <NavLink to="/registration" exact>Signup</NavLink>
                        </Nav.Item>
                      </>
                    ) : (
                      <>
                        <Nav.Item as="li">
                          <NavLink to="/user-profile" exact>MY PROFILE</NavLink>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <NavLink
                            onClick={() => this.logoutUser()}
                            style={{ cursor: "pointer" }}
                            to="javascript:void(0);"
                          >
                            Logout
                          </NavLink>
                        </Nav.Item>
                      </>
                    )} */}
                    {/* <Nav.Item as="li">
                      <a href={`https://x10d.org?${Encrypt(this.state.encriptUserData)}`} target="_blank" rel="noopener noreferrer">
                        Stocket<sup>&nbsp;TM</sup>
                      </a>
                    </Nav.Item> */}
                    <Nav.Item as="li">
                      <NavLink to="/stocket" exact>Stocket<sup>&nbsp;TM</sup></NavLink>
                    </Nav.Item>
                  </Nav>
                </Navbar.Collapse>

                <div className="nav__right">
                    {localStorage.getItem("authToken") === null ? (
                      <Nav as="ul">
                        <Nav.Item as="li">
                          <NavLink to="/login" exact><i className="fa fa-user-o" aria-hidden="true"></i> Sign in
                          </NavLink>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <NavLink to="/registration" exact><i className="fa fa-sign-in" aria-hidden="true"></i> Sign up</NavLink>
                        </Nav.Item>
                        </Nav>
                    ) : (
                      <Dropdown>
                        <Dropdown.Toggle>
                          <img width="30" height="30" alt="img" src={ this.state.userimage !== "" ? `${this.state.userimage}`: avatar } className="rounded-circle" /> <span>Welcome <span className="user__name">{this.state.username}</span></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <NavLink className="dropdown-item" to="/user-profile" exact><i className="fa fa-user-o" aria-hidden="true"></i> MY PROFILE</NavLink>
                          <NavLink className="dropdown-item" style={{ cursor: "pointer" }} to="javascript:void(0);" onClick={() => this.logoutUser()}><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</NavLink>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                </div>

            </Navbar>
          </Container>
        </div>
      </header>
    );
  }
}
export default withRouter(Header);
//export default Header;
