import React, { Component } from "react";
import axios from "axios";
import { finallogo } from "../../../../assets/images/"
import './footer.css';
import { message } from "antd";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("authToken");
    this.state = {
      loading: false,
      phone: '',
      email: '',
      address: '',
      privacy_media_url: '',
      terms_media_url: '',
    }
  }

  fetchcontactus = () => {
    this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/contact-us-cms-fetch`, {
        withCredentials: true,

      })
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data.details.length) {
            this.setState({ phone: res.data.details[0].phone, email: res.data.details[0].email, address: res.data.details[0].address, loading: false });
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  // get privacy-policy url
  fetchPrivacy = async () => {
    this.setState({ loading: true });
    await axios
      .get(`${process.env.REACT_APP_API_URL}/get_privacypolicy_content`, {
        withCredentials: true,
        headers: {
          "bearer": this.token,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          this.setState({ privacy_media_url: res.data.deta[0].media_url, loading: false });
        }

      })
      .catch((err) => {
        this.setState({ loading: false });
        // message.error(err?.response?.data?.msg);
        console.log("Error... ", err);
      });
  };

  // get terms & conditions url
  fetchTermsAndConditions = async () => {
    this.setState({ loading: true });
    await axios
      .get(`${process.env.REACT_APP_API_URL}/get_terms_condition_content`, {
        withCredentials: true,
        headers: {
          "bearer": this.token,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          this.setState({ terms_media_url: res.data.deta[0].media_url, loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        // message.error(err?.response?.data?.msg);
        console.log("Error... ", err);
      });
  };

  componentDidMount() {
    this.fetchcontactus();
    this.fetchPrivacy();
    this.fetchTermsAndConditions();
  }

  contactPage() {
    // window.open = "/contact"
    window.location = "/contact";
  }

  faqPage() {
    window.location = "/faq";
  }

  privacyPage = async (e) => {
    // window.location = "/privacy";
    e.preventDefault();
    let { privacy_media_url } = this.state

    this.downloadfile(privacy_media_url.slice(privacy_media_url.lastIndexOf("/") + 1))
  }

  termsCondition = async (e) => {
    // window.location = "/terms-conditions"
    e.preventDefault();
    let { terms_media_url } = this.state
    this.downloadfile(terms_media_url.slice(terms_media_url.lastIndexOf("/") + 1))
  }

  downloadfile = async (filename) => {
    fetch(`${process.env.REACT_APP_API_URL}/download_file/${filename}`, { method: "GET" }).then((response) => response.blob()).then((blobContent) => {
      let pdfUrl = window.URL.createObjectURL(blobContent)
      let link = document.createElement("a")
      link.href = pdfUrl
      link.download = filename
      document.body.appendChild(link)
      link.click();
      link.remove();
    }).catch((error) => {
      // message.error(error.response ? error.response.data.msg : "Something wrong !")
      // message.error(error?.response?.data?.msg);
      console.log("Error... ", error);
    })
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container">
            <div className="footer__middle row align-items-center">
              <div className="col-md-7">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <div className="footer__logo">
                      <img src={finallogo} alt="img" className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="address">
                      <ul>

                        <a href={`mailto:${this.state.email}`}>
                          <li>Email: <span style={{ color: "#1890ff" }}>{this.state.email}</span></li>
                        </a>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="sub_footer">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <ul className="sub__footer__menu">
                  <li onClick={() => this.contactPage()} style={{ color: "white", cursor: "pointer" }}>Contact Us</li>
                  <li onClick={this.privacyPage} style={{ color: "white", cursor: "pointer" }}>Privacy Policy</li>
                  <li onClick={this.termsCondition} style={{ color: "white", cursor: "pointer" }}>Terms & conditions</li>
                </ul>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="copytight__info">
                  <p>BSA© 2021. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Footer
